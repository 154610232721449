import React from 'react';
import styled from 'styled-components';

import './JobOfferStyle.css';
import { mobileThresholdPixels, colors, margins } from '../../Home/v2/StyledComponents';

const Container = styled.div`
  color: ${colors.navy};
  margin: ${margins.xl} 0px;

  @media (max-width: ${mobileThresholdPixels}) {
    margin: ${margins.l} auto;
  }
`;

function setWelcomeKit() {
  welcomeKitReady(() => {
    const wk = new WelcomeKitEmbed('j8W7YXl');
    wk.group('job');
    wk.display(['department', 'officeCity', 'contractType']);
    wk.locale('fr');
    wk.website('wttj_fr');
    wk.render();
  });
}

function loadScript(src) {
  return new Promise((resolve, reject) => {
    if (typeof document !== 'undefined') {
      const script = document.createElement('script');
      script.src = src;
      script.addEventListener('load', () => resolve());
      script.addEventListener('error', error => reject(error));
      document.body.appendChild(script);
    }
  });
}

const welcomeScriptLoading = loadScript('https://www.welcomekit.co/assets/embed.js');

class JobOfferWelcomeToTheJungle extends React.Component {
  constructor() {
    super();
    if (typeof document !== 'undefined') {
      welcomeScriptLoading.then(() => setWelcomeKit());
    }
  }

  render() {
    return (
      <Container><div id="welcomekit-embed" /></Container>
    );
  }
}

export default JobOfferWelcomeToTheJungle;
